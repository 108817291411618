import React from "react";
import "./styles.css";

import bg from "../../assets/bg.svg";
import {
  expertiseIntegration,
  expertiseCode,
  expertiseSharepoint,
  expertiseMobileDev,
  expertiseGear,
} from "../../assets";
export default function Expertises() {
  return (
    <section className="services-section">
      <img className="services-bg" src={bg} alt="bg" />
      <div className="expertise-container">
        <div className="services-right">
          <Item
            title="Intégration de solutions ERP pour PME/PMI et Grands Comptes"
            desc="L’implémentation de progiciels de gestion est le cœur de métier d’IB Solutions. L’expérience et l’expertise de nos équipes dans ce domaine nous permettent aujourd’hui de répondre et nous adapter aux exigences de chaque client pour leur garantir une solution qui améliore leurs activités et performances au quotidien."
            img={expertiseIntegration}
          />
          <Item
            title="Développement spécifique"
            desc="Le développement sur mesure vous permet de réaliser des solutions spécifiques à votre métier dans l’optique de résoudre une série de problèmes ou bien d’améliorer votre activité pour une gestion et organisation plus efficace.
            Ce travail se fait en se basant sur les nouvelles technologies de développement Web (Front-end et Back- end) et Desktop sur différentes plateformes."
            img={expertiseCode}
          />
          <Item
            title="Développement BI et Sharepoint"
            desc="Les solutions décisionnelles sont conçues et développées en complément pour les projets d’implémentation et pour les clients qui souhaitent une BI poussée, en leur fournissant des insights personnalisés et plus de possibilités en matière de restitution, de graphique, de tableaux de bord ainsi que des graphiques dynamiques.
            Nos projets SharePoint confèrent aux entreprises une meilleure gestion des documents, projets, automatisation des processus et procédures de travail ainsi que l’intégration des données émanant d’autres applications (ERP, CRM…etc.)"
            img={expertiseSharepoint}
          />
          <Item
            title="Développement d'application mobile"
            desc="Nos prestations de développement d’application mobile ont pour but de répondre aux enjeux de la mobilité en entreprise afin d’améliorer l’accessibilité et la réactivité de vos collaborateurs nomades."
            img={expertiseMobileDev}
          />
          <Item
            title="Support et TMA"
            desc="L’implémentation d’un logiciel ERP ne s’arrête pas à l’installation et au démarrage de la solution. C’est pourquoi, IB Solutions vous propose un support post go-live et une TMA adaptée à vos besoins pour assurer la continuité, la pérennité et l’évolution de votre système d’information."
            img={expertiseGear}
            last
          />
        </div>
        <div className="expertise-left">
          <div>
            <h2 className="expertise-left-title" data-aos="fade-up">
              Notre expertise s’appuie sur une équipe pluridisciplinaire de consultants aux profils complémentaires pour
              mener à bien notre mission.
            </h2>
            <p className="services-left-text" data-aos="fade-up"></p>
          </div>
        </div>
      </div>
    </section>
  );
}

function Item({ title, desc, img, last, width = 100, height = 100 }) {
  return (
    <div className="services-right-item">
      <div data-aos="fade-up">
        <h3 className="services-right-item-title">{title}</h3>
        <p className="services-right-item-text">{desc}</p>
        {!last && <div className="services-right-item-border" />}
      </div>
      <div data-aos="fade-up" className="services-right-item-img">
        <img width={`${width}px`} height={`${height}px`} src={img} alt="expertise" />
      </div>
    </div>
  );
}
