import cetic from "./cetic.png";
import expertiseCode from "./expertiseCode.svg";
import expertiseGear from "./expertiseGear.svg";
import expertiseIntegration from "./expertiseIntegration.svg";
import mission1 from "./mission1.svg";
import mission2 from "./mission2.svg";
import expertiseMobileDev from "./expertiseMobileDev.svg";
import productAddon from "./product-addon.png";
import productCrm from "./product-crm.jpg";
import product from "./product.gif";
import expertiseSharepoint from "./sharepoint.png";
import silverProd from "./silverprod.png";
import slide1 from "./slide1.png";
import slide2 from "./slide2.jpg";
import slide3 from "./slide3.png";
import slide4 from "./slide4.jpg";
import slide5 from "./slide5.jpg";
import solutionERP from "./solution-erp.png";
import solutionDynamicsBusiness from "./solution-microsoft-dynamics-business.png";
import solutionDynamicsBusinessSide from "./solution-microsoft-dynamics-business.webp";
import solutionDynamicsBusinessCrm from "./solution-microsoft-dynamics-crm.jpg";
import solutionDynamicsWheel from "./solution-microsoft-dynamics-wheel.png";
import solutionDynamics from "./solution-microsoft-dynamics.png";
import solutionDynamics365 from "./solution-microsoft-dynamics365.png";

export {
  expertiseIntegration,
  expertiseCode,
  expertiseSharepoint,
  expertiseMobileDev,
  expertiseGear,
  product,
  productCrm,
  productAddon,
  cetic,
  slide1,
  slide2,
  slide3,
  slide4,
  slide5,
  mission1,
  mission2,
  solutionERP,
  solutionDynamics,
  solutionDynamics365,
  solutionDynamicsWheel,
  solutionDynamicsBusiness,
  solutionDynamicsBusinessSide,
  solutionDynamicsBusinessCrm,
  silverProd,
};
