import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export function useMobile(width) {
  const [isMobileSize, setIsMobileSize] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", resize);
    resize();
    return () => {
      window.removeEventListener("resize", resize);
    };
  });

  function resize() {
    let innerWidth = window.innerWidth <= width;
    if (innerWidth !== isMobileSize) {
      setIsMobileSize(innerWidth);
    }
  }

  return isMobileSize;
}

// Hook
// https://github.com/donavon/use-event-listener
export function useEventListener(eventName, handler, element = global) {
  // Create a ref that stores handler
  const savedHandler = useRef();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      // Make sure element supports addEventListener
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;

      // Create event listener that calls handler function stored in ref
      const eventListener = (event) => savedHandler.current(event);

      // Add event listener
      element.addEventListener(eventName, eventListener);

      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element] // Re-run if eventName or element changes
  );
}

export function map(value, start1, stop1, start2, stop2) {
  return start2 + (stop2 - start2) * ((value - start1) / (stop1 - start1));
}

export function hashScroll(hash, options) {
  if (typeof hash !== "string") return;

  const _options = { ...{ defaultOffset: 80, hashOffsets: {} }, ...options };

  const { defaultOffset, hashOffsets } = _options;

  let offset = hash in hashOffsets ? hashOffsets[hash] : defaultOffset;

  const element = document.getElementById(hash);
  if (!element) {
    console.log("no element with this # on this page !");
    return;
  }
  const elementPosition = element.offsetTop - offset;

  window.scroll({
    top: elementPosition,
    left: 0,
    behavior: "smooth",
  });
}

export function useHashScroll(options) {
  const location = useLocation();
  useEffect(() => {
    let to;
    if (location.hash) {
      to = setTimeout(() => {
        hashScroll(location.hash.replace("#", ""), options);
      }, 100);
    }

    return () => clearTimeout(to);
  }, [location.hash, options]);
}
