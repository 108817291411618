import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";

import Header from "./components/Header";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import AOS from "aos";
import "aos/dist/aos.css";

import Loader from "./components/Loader";
import Expertises from "./screens/Expertises";

const Footer = React.lazy(() => import("./components/Footer"));
const Accueil = React.lazy(() => import("./screens/Accueil"));
const Contact = React.lazy(() => import("./screens/Contact"));
const Societe = React.lazy(() => import("./screens/Societe"));
const NotFound = React.lazy(() => import("./screens/NotFound"));
const Solutions = React.lazy(() => import("./screens/Solutions"));
const WorkInProgress = React.lazy(() => import("./components/WorkInProgress"));

AOS.init({
  duration: 600,
});

function App() {
  const location = useLocation();
  AOS.refresh();

  React.useEffect(() => {
    if (!location.hash) window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Header light={false} />
      <React.Suspense fallback={<Loader />}>
        <Switch>
          <Route exact path="/">
            <Accueil />
          </Route>
          <Route exact path="/solutions">
            <Solutions />
          </Route>
          <Route exact path="/products">
            <WorkInProgress />
          </Route>
          <Route exact path="/contact">
            <div style={{ background: "white", height: "95px" }} />
            <Contact />
          </Route>
          <Route exact path="/about">
            <Societe />
          </Route>
          <Route exact path="/expertise">
            <Expertises />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
        <Footer />
        <ToastContainer autoClose={6000} />
      </React.Suspense>
    </>
  );
}

export default App;
