import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import logo_light from "../../assets/logo-footer.svg";
import logo from "../../assets/logo.svg";
import logo_icon from "../../assets/logo_icon.svg";
import { useEventListener, useMobile } from "../../helpers/customHooks";
import { Link } from "../Link";
import "./styles.css";

function Header({ light, setSlideVisible }) {
  const isMobile = useMobile(1080);
  const [onTop, setOnTop] = useState(true);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    if (!isMobile) {
      setShowMenu(true);
    } else {
      setShowMenu(false);
    }
  }, [isMobile]);

  const handleOutSideClick = (e) => {
    if (e.target.className !== "hamburger" && e.target.className !== "hamburger-piece")
      if (showMenu) setShowMenu(false);
  };

  useEventListener("click", handleOutSideClick, document.getElementById("root"));

  const handleHeaderOnTop = () => {
    if (window.scrollY < 60) {
      if (!onTop) setOnTop(true);
    } else {
      if (onTop) setOnTop(false);
    }
  };

  useEventListener("scroll", handleHeaderOnTop);

  function handleShowMenu(e) {
    setShowMenu(!showMenu);
  }

  function onItemClick() {}

  return (
    <header
      className={classNames({
        "header-container": true,
        "header-container-light": light,
        "header-on-top": onTop,
      })}
    >
      {!onTop ? (
        <Link to="/">
          <img width={"30px"} height={"30px"} src={logo_icon} alt={"logo"} className={"logo"} />
        </Link>
      ) : (
        <Link to="/">
          <img width={"130px"} height={"30px"} src={light ? logo_light : logo} alt={"logo"} className={"logo"} />
        </Link>
      )}

      {isMobile && (
        <div className="hamburger" onClick={handleShowMenu}>
          <i className="hamburger-piece" />
          <i className="hamburger-piece" />
          <i className="hamburger-piece" />
        </div>
      )}
      {(showMenu || !isMobile) && (
        <ul
          className={classNames({
            "header-menu": true,
            "header-menu-light": light,
          })}
        >
          <MenuItem path="/" value="Accueil" onClick={onItemClick} />

          <MenuItem path="/solutions" value="Nos solutions" onClick={onItemClick}>
            <SubMenu>
              <Link to="/solutions#ERP" className="sub-menu-item">
                Solutions ERP
              </Link>
              <Link to="/solutions#CRM" className="sub-menu-item">
                CRM
              </Link>
              <Link to="/solutions#ADD-ON" className="sub-menu-item">
                ADD-ON
              </Link>
            </SubMenu>
          </MenuItem>

          <MenuItem path="/expertise" value="Expertises" onClick={onItemClick} />

          <MenuItem path="/about" value="Société" onClick={onItemClick}>
            <SubMenu>
              <Link to="/about#who" className="sub-menu-item">
                Qui sommes-nous ?
              </Link>
              <Link to="/about#mission" className="sub-menu-item">
                Mission et Valeurs
              </Link>
              <Link to="/about#gd-word" className="sub-menu-item">
                Mot de la DG
              </Link>
              <Link to="/about#partners" className="sub-menu-item">
                Nos partenaires
              </Link>
              <Link to="/about#clients" className="sub-menu-item">
                Nos clients
              </Link>
            </SubMenu>
          </MenuItem>
          <MenuItem path="/contact" value="Contact" />
        </ul>
      )}
    </header>
  );
}

function MenuItem({ path, value, onClick, children }) {
  const location = useLocation();
  const [subMenuVisible, setSubMenuVisible] = useState(false);
  function handleEnter() {
    setSubMenuVisible(true);
  }

  function handleExit() {
    setSubMenuVisible(false);
  }

  const isActive = location.pathname === path;

  return (
    <li
      className={`menu-item ${isActive && "active"}`}
      onMouseEnter={handleEnter}
      onMouseLeave={handleExit}
      onClick={onClick}
    >
      <Link to={path}>{value}</Link>
      <div className="menu-item-border" />
      {subMenuVisible && children}
    </li>
  );
}

function SubMenu(props) {
  return (
    <div className="sub-home-menu-container">
      <ul className="sub-home-menu">
        {props.children}
        <svg
          style={{
            position: "absolute",
            top: -5,
            zIndex: -1,
            transform: "rotate(-45deg)",
            boxShadow: "2px -2px 3px 0px rgba(0,0,0,.1)",
          }}
          width="15px"
          height="15px"
        >
          <path d="M0,0 20,0 20,20" fill="#fff" />
          {/* <rect width='20px' height='20px' fill='#f00' stroke='black' /> */}
        </svg>
      </ul>
    </div>
  );
}

export default Header;
